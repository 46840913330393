import { Handshake } from '@styled-icons/fa-regular';
import { Handshake as HandshakeFull } from '@styled-icons/fa-solid';
import { Icon, IconProps } from './Icon';

export function HandShakeIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <Handshake />
    </Icon>
  );
}
export function HandShakeFullIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <HandshakeFull />
    </Icon>
  );
}
