import { Magic } from '@styled-icons/remix-line';
import { Magic as MagicFill } from '@styled-icons/remix-fill';

import { Icon, IconProps } from './Icon';

export function WishIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <Magic />
    </Icon>
  );
}

export function WishFullIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <MagicFill />
    </Icon>
  );
}
