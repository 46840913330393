import { MailCheck } from '@styled-icons/remix-line';
import { MailCheck as MailCheckFill } from '@styled-icons/remix-fill';

import { Icon, IconProps } from './Icon';

export function ApplyIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <MailCheck />
    </Icon>
  );
}

export function ApplyFullIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <MailCheckFill />
    </Icon>
  );
}
